















































































































import { Component, Ref } from "vue-property-decorator";
import { BaseView } from "@/core/UI/Views/BaseView";
import { MutationLoginArgs } from "@/generated/graphql";
import { Routes } from "@/types/core";
import PageCard from "@components/page/Card.vue";
import { sendResetPasswordEmail } from "@/graphql/queries/User.graphql";
import PasswordInput from "@/components/widgets/commons/Inputs/PasswordInput.vue";
import * as rules from "@/core/validation";
import { VForm } from "vuetify/lib/components";

@Component({
  components: {
    PageCard,
    PasswordInput,
  },
})
export default class AuthorizationForm extends BaseView {
  protected readonly sendResetPasswordEmail = sendResetPasswordEmail;
  protected readonly rules = rules;
  protected resetEmailSent = false;
  protected resetEmail = "";

  protected step: number = 1;
  protected inProgress: boolean = false;
  protected error: string = "";
  protected model: MutationLoginArgs = {
    login: "",
    password: "",
    remember: false,
  };

  @Ref() readonly formAuth?: InstanceType<typeof VForm> & {
    validate: () => boolean;
  };

  protected async submit(): Promise<void> {
    if (!this.formAuth?.validate()) return;
    try {
      this.inProgress = true;
      await this.$store.dispatch("session/login", this.model);
      await this.$store.dispatch("session/getMe");
      window.location.replace("/users");
      // await this.$router.push({ name: Routes.users });
      // this.$emit("loggedIn");
    } catch (e: unknown) {
      //@ts-ignore
      if (e.graphQLErrors) {
        this.error =
          //@ts-ignore
          e.graphQLErrors[0]?.message ?? "Неизвестная ошибка, попробуйте позже";
      } else {
        this.error = "Неизвестная ошибка, попробуйте позже";
      }
    } finally {
      this.inProgress = false;
    }
  }
}
