













import { Component } from "vue-property-decorator";
import Authorization from "@widgets/commons/Account/Authorization.vue";
import { Routes } from "@/types/core";
import { BaseView } from "@/core/UI/Views/BaseView";

@Component({
  name: "login",
  components: { Authorization },
  async beforeCreate() {
    if (this.$store.getters["session/logged"])
      await this.$router.replace(Routes.users);
  },
})
export default class Login extends BaseView {}
